import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"


const PostPage = ( {post} ) => {
  const image = post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  const altText = post.featuredImage?.node?.altText || ``
  const bylinetext = post.author.node.name.concat(' - ').concat(post.date)
  return (
    <div className="container mx-auto font-opensans mt-5 px-6">
      <h1 
        className="text-2xl"
        dangerouslySetInnerHTML={{ __html: post.title }} />
      <h4 dangerouslySetInnerHTML={{ __html: bylinetext }} />
      <GatsbyImage 
        image={image} 
        alt={altText} />
      <div 
        className="mt-6"
        dangerouslySetInnerHTML={{ __html: post.content }} />    
    </div>

    );
}

export default PostPage