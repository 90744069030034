import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PostPage from "../components/postpage"
import Seo from 'gatsby-plugin-wpgraphql-seo';

export const query = graphql`
query GET_POST($id: String!) {
    wpPost(id: {eq: $id}) {
      title
      date(formatString: "MMMM DD, YYYY")
      author {
        node {
          name
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }  
`
const PostTemplate = ( {data} ) => {
    const post = data.wpPost;
    
    return (
        <Layout>
            <Seo post={post} />
            <PostPage post={post}/>
        </Layout>
    );
}

export default PostTemplate